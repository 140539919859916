<template>
	<!-- 审阅-测试用例详情 -->
	<div>
		<GModule class="container">
			<GTitle name="审阅-测试用例"></GTitle>
			<div class="detail-all">
				<a-form
					class="need-box"
				    :model="formState"
				    name="basic"
				    :label-col="{ span: 6}"
				    :wrapper-col="{ span: 16}"
				    autocomplete="off"
					:scrollToFirstError='true'
				    @finish="onFinish"
				    @finishFailed="onFinishFailed">
					<a-form-item
						label="用例ID" 
						name="ID" 
						:colon= "false" 
						:rules="[{ required: true, message: '请输入用例ID' }]">
					    <a-input v-model:value="formState.ID" placeholder="请输入用例ID" />
					</a-form-item>
					<a-form-item
						label="用例类型" 
						name="type" 
						:colon= "false" 
						:rules="[{ required: true, message: '请输入用例类型' }]">
					    <a-input v-model:value="formState.type" placeholder="请输入用例类型" />
					</a-form-item>
					<a-form-item
						label="提审时间" 
						name="type" 
						:colon= "false" 
						:rules="[{ required: true, message: '请输入提审时间' }]">
					    <a-input v-model:value="formState.type" placeholder="请输入提审时间" />
					</a-form-item>
					<a-form-item
						label="状态" 
						name="type" 
						:colon= "false" 
						:rules="[{ required: true, message: '请输入状态' }]">
					    <a-input v-model:value="formState.type" placeholder="请输入状态" />
					</a-form-item>
					<a-form-item 
						label="用例摘要" 
						name="describe" 
						:colon= "false" 
						:rules="[{ required: true, message: '请输入用例摘要' }]">
					    <a-textarea placeholder="用例摘要" style="height: 150px;" v-model:value="formState.describe" />
					</a-form-item>
					<a-form-item
						label="前置条件" 
						name="describe" 
						:colon= "false" 
						:rules="[{ required: true, message: '请输入前置条件' }]">
					    <a-textarea placeholder="前置条件" style="height: 150px;" v-model:value="formState.describe" />
					</a-form-item>
					<a-form-item
						label="操作步骤" 
						name="describe" 
						:colon= "false" 
						:rules="[{ required: true, message: '请输入操作步骤' }]">
					    <a-textarea placeholder="操作步骤" style="height: 150px;" v-model:value="formState.describe" />
					</a-form-item>
					<a-form-item
						label="预期结果" 
						name="describe" 
						:colon= "false" 
						:rules="[{ required: true, message: '请输入预期结果' }]">
					    <a-textarea placeholder="预期结果" style="height: 150px;" v-model:value="formState.describe" />
					</a-form-item>
					<a-form-item
						label="需求类型" 
						name="describe" 
						:colon= "false" 
						:rules="[{ required: false }]">
					   啦啦啦
					</a-form-item>
					<a-form-item
						label="需求模块" 
						name="describe" 
						:colon= "false" 
						:rules="[{ required: false }]">
					    <a-breadcrumb separator=">" style="width: 400px;margin-bottom: 14px;">
					        <a-breadcrumb-item>需求</a-breadcrumb-item>
					        <a-breadcrumb-item>需求2</a-breadcrumb-item>
					        <a-breadcrumb-item>Application List</a-breadcrumb-item>
					        <a-breadcrumb-item>An Application</a-breadcrumb-item>
					    	<a-breadcrumb-item>需求</a-breadcrumb-item>
					    	<a-breadcrumb-item>需求2</a-breadcrumb-item>
					    	<a-breadcrumb-item>Application List</a-breadcrumb-item>
					    	<a-breadcrumb-item>An Application</a-breadcrumb-item>
					     </a-breadcrumb>
					</a-form-item>
					<a-form-item
						label="需求描述" 
						name="describe" 
						:colon= "false" 
						:rules="[{ required: true, message: '请输入需求描述' }]">
					    <a-textarea placeholder="需求描述" style="height: 150px;" v-model:value="formState.describe" />
					</a-form-item>
					<a-form-item
						label="参数/逻辑说明" 
						name="describe" 
						:colon= "false" 
						:rules="[{ required: true, message: '请输入参数/逻辑说明' }]">
					    <a-textarea placeholder="参数/逻辑说明" style="height: 90px;" v-model:value="formState.describe" />
					</a-form-item>
					<a-form-item
						label="需求图片" 
						name="imgList" 
						:colon= "false" 
						:rules="[{ required: false }]">
					    <div class="img-scroll" @click="openSwiper()">
					    	<a-image :preview="false" :width="90" :src="itm.url" v-for="(itm,ind) in imgArr" :key="ind"/>
					    </div>
					</a-form-item>
					<a-form-item  :wrapper-col="{ offset:7 }">
					  <a-button class="btn clo6">返回</a-button>
					  <a-button class="btn clo1">通过</a-button>
					  <a-button class="btn" type="danger">驳回</a-button>
					</a-form-item>
				</a-form>
			</div>
		</GModule>	

	</div>
</template>

<script>
	export default{
		name:'TheTestCaseDetail',
		data(){
			return{
				formState :{
					type:'',
					describe:'',
					explain:'',
					imgList:[]
				},
				imgArr:[
					{url:'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',isCheck:false},
					{url:'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',isCheck:false},
					{url:'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',isCheck:false},
					{url:'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',isCheck:false},
					{url:'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',isCheck:false},
					{url:'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',isCheck:false},
					{url:'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',isCheck:false}
				],
				imgArr2:[
					{url:'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',isCheck:false},
					{url:'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',isCheck:false},
					{url:'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',isCheck:false},
					{url:'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',isCheck:false},
					{url:'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',isCheck:false},
					{url:'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',isCheck:false},
					{url:'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',isCheck:false}
				]
			}
		},
		mounted() {
			this.$store.commit('setImgOtherArr',this.imgArr)
		},
		methods:{
			openSwiper(){
			  // 弹出图片列表
			  this.$store.commit('setSwiper',1)
			},
			openDownloadImgs(){
				// 弹出图片下载
				this.$store.commit('setImgOther',1)
			}
		}
	}
</script>

<style lang="scss" scoped>
.container{
	margin: 20px;
	.detail-all{
		display: flex;
		justify-content: center;
		padding: 20px 0 0;
		// max-height: 92%;
		overflow-y: auto;
		overflow-x: hidden;
		&::-webkit-scrollbar {
			width: 0px; // 控制滚动条
		}
		&::-webkit-scrollbar-thumb{
			background-color: #bfbfbf; // 滚动条颜色
		}
		&:hover{
			&::-webkit-scrollbar {
				width: 1px;
			}
		}
		.need-box{
			.btn{
				margin-right: 10px;
			}
			.img-scroll{
				height: 90px;
				overflow-x: auto;
				overflow-y: hidden;
				display: flex;
				align-items: center;
				&::-webkit-scrollbar {
					height: 0px; // 控制滚动条
				}
				&::-webkit-scrollbar-thumb{
					background-color: #a5ddff; // 滚动条颜色
				}
				&:hover{
					&::-webkit-scrollbar {
						height: 8px;

					}
				}
			}
			.overimg-box2{
				.overimg{
					display: flex;
					height: 90px;
					overflow: hidden;
				}
			}
		}
	}
}
</style>